import React, { useEffect } from "react"
import LayoutComponent from "../components/LayoutComponent/LayoutComponent"
import MenuComponent from "../components/MenuComponent/MenuComponent"
import "../styles/global.scss"
import NewsData from "../components/NewsComponent/data.json"
import { Link } from "gatsby"
import "../styles/NewsPageStyle.scss"
import EmptyImage from "../assets/image/bcrd_placeholder.png"
import { unloadRecaptcha } from "../utils/Captcha"

const News = () => {
  useEffect(() => {
    unloadRecaptcha()
  }, [])

  return (
    <LayoutComponent languageSwitchSubpage="news">
      <MenuComponent selected={0} />
      {NewsData.map((value, index) => {
        return (
          <div key={index} className="news-list-wrapper">
            <Link to={"/news/" + value.slug}>
              {value.image === "" ? (
                <img src={EmptyImage} />
              ) : (
                <img src={"/news/images/" + value.image} />
              )}
              <div className="news-list-text-wrapper">
                <p className="news-list-text-headline">{value.title}</p>
                <p className="news-list-text-content">{value.subtitle}</p>
              </div>
            </Link>
          </div>
        )
      })}
    </LayoutComponent>
  )
}

export default News
